/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../layouts/Default';
import Section from '../components/Section';

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundColor: '#000000',
    color: '#000000',

  },
}));

export const AboutPageTemplate = ({ post, classes }) => {
  const { body, frontmatter: { title } } = post;
  return (
    <Section className={`${classes.section} content`}>
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

AboutPageTemplate.propTypes = {
  post: PropTypes.object.isRequired,
};

const AboutPage = ({ data }) => {
  const classes = useStyles();
  const { mdx: post } = data;
  return (
    <Layout>
      <AboutPageTemplate
        post={post}
        classes={classes}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    mdx(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
      }
      body
    }
  }
`;
